/* You can add global styles to this file, and also import other style files */
.toast-container .ngx-toastr {
    position: relative !important;
    overflow: hidden !important;
    margin: 0 0 6px !important;
    padding: 15px 15px 15px 50px !important;
    width: 300px !important;
    border-radius: 3px !important;
    background-position: 15px center !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    box-shadow: 0 0 12px #999999 !important;
    color: #FFFFFF !important;
}

.toast-warning {
    background-color: #f89406 !important;
}

.ngx-toastr {
    pointer-events: auto !important;
}

//04-11-2024 hide cross button of toaster
.toast-container .ngx-toastr .toast-close-button {
    display: none !important;
}

.c-pointer{
    cursor: pointer;
}
.rts-button{
    background-color: #553cdf !important;
}

.error-code{
    color: #FF3333 !important;
}