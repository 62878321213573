/* image upload css start */

.avatar-upload {
    position: relative;
    max-width: 205px;
    /* margin: 50px auto; */
}
.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}
.avatar-upload .avatar-edit input {
    display: none;
}
.avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}
.avatar-upload .fa-pencil-alt{
    vertical-align: middle;
    margin-left: 5px;
    font-size: 18px;
    margin-top: 5px;
}
.avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}
.avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


/* image upload css end */

/* plus minus collapse  css */

.search-mobile[aria-expanded=true] i:first-child, .sidebar-collapse>a[aria-expanded=true] i:first-child{display:none;}
.search-mobile[aria-expanded=false] i:last-child, .sidebar-collapse>a[aria-expanded=false] i:last-child{display:none;}

.collapse-accordion-toggle[aria-expanded=true] div i:not(:first-child), .collapse-accordion-toggle[aria-expanded=true] span i:not(:first-child){
    display: none;
}
.collapse-accordion-toggle[aria-expanded=false] div i:not(:last-child), .collapse-accordion-toggle[aria-expanded=false] span i:not(:last-child){
    display: none;
}


.footer-accordion-toggle[aria-expanded=true] div i.fas:not(:first-child), .footer-accordion-toggle[aria-expanded=true] span i.fas:not(:first-child){
    display:none;
}



.footer-accordion-toggle[aria-expanded=false] div i.fas:not(:last-child), .footer-accordion-toggle[aria-expanded=false] span i.fas:not(:last-child) {
    display: none;
}

.fa-gray{
    color:#77838f;
}
/* plus minus collaps  css */


/*==========Bubbles Animation CSS Start==========*/

.bubbles_wrap {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: hidden;
    pointer-events: none;
}

@-webkit-keyframes animateBubble {
    0% {
        margin-top: 20%
    }

    to {
        margin-top: -30%
    }
}

@-moz-keyframes animateBubble {
    0% {
        margin-top: 20%
    }

    to {
        margin-top: -30%
    }
}

@keyframes animateBubble {
    0% {
        margin-top: 20%
    }

    to {
        margin-top: -30%
    }
}

@-webkit-keyframes sideWays {
    0% {
        margin-left: 0
    }

    to {
        margin-left: 25px
    }
}

@-moz-keyframes sideWays {
    0% {
        margin-left: 0
    }

    to {
        margin-left: 25px
    }
}

@keyframes sideWays {
    0% {
        margin-left: 0
    }

    to {
        margin-left: 25px
    }
}

.x1 {
    -webkit-animation: animateBubble 15s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 15s linear infinite, sideWays 2s ease-in-out infinite alternate;
    left: 6%;
    top: 45%;
    background: #ff7812;
}

.x2 {
    -webkit-animation: animateBubble 10s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 10s linear infinite, sideWays 4s ease-in-out infinite alternate;
    left: 5%;
    top: 80%;
    background: #390f00;
}

.x3 {
    -webkit-animation: animateBubble 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
    left: 10%;
    top: 40%;
    background: #ff7812;
}

.x4 {
    -webkit-animation: animateBubble 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
    animation: animateBubble 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
    left: 20%;
    top: 0;
    background: #390f00;
}

.x5 {
    -webkit-animation: animateBubble 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
    left: 30%;
    top: 50%;
    background: #ff7812;
}

.x6 {
    -webkit-animation: animateBubble 11s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 11s linear infinite, sideWays 2s ease-in-out infinite alternate;
    left: 50%;
    top: 0;
    background: #390f00;
}

.x7 {
    -webkit-animation: animateBubble 10s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 10s linear infinite, sideWays 2s ease-in-out infinite alternate;
    left: 65%;
    top: 70%;
    background: #ff7812;
}

.x8 {
    -webkit-animation: animateBubble 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
    animation: animateBubble 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
    left: 80%;
    top: 10%;
    background: #390f00;
}

.x9 {
    -webkit-animation: animateBubble 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: animateBubble 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
    left: 90%;
    top: 50%;
    background: #ff7812;
}

.x10 {
    -webkit-animation: animateBubble 16s linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble 16s linear infinite, sideWays 2s ease-in-out infinite alternate;
    left: 80%;
    top: 80%;
    background: #390f00;
}

.bubble {
    position: absolute;
    border-radius: 2px;
    box-shadow: none;
    width: 5px;
    height: 5px;
}

/*==========Bubbles Animation CSS End==========*/
/*==========Background color CSS Start==========*/
.main-banner-in {
    position: relative;
}

.main-banner-in::before {
    content: "";
    position: absolute;
    width: 900px;
    height: 900px;
    border-radius: 100%;
    top: -150px;
    left: -370px;
    opacity: 0.5;
    /* background: radial-gradient(ellipse at center, rgba(0, 95, 252, 0.6) 0%, rgba(0, 95, 252, 0.6) 0%, rgba(0, 95, 252, 0) 68%, rgba(0, 95, 252, 0) 100%);
    -webkit-background: radial-gradient(ellipse at center, rgba(0, 95, 252, 0.6) 0%, rgba(0, 95, 252, 0.6) 0%, rgba(0, 95, 252, 0) 68%, rgba(0, 95, 252, 0) 100%); */
    /* background: radial-gradient(ellipse at center, rgba(72, 22, 7, 0.8) 0%, rgba(72, 22, 7, 0.8) 0%, rgba(72, 22, 7, 0) 68%, rgba(72, 22, 7, 0) 100%); */
    -webkit-background: radial-gradient(ellipse at center, rgba(72, 22, 7, 0.6) 0%, rgba(72, 22, 7, 0.6) 0%, rgba(72, 22, 7, 0) 68%, rgba(72, 22, 7, 0) 100%);
    z-index: 0;
    background: radial-gradient(ellipse at center, rgb(255 132 39) 0%, rgb(255 120 18) 0%, rgba(72, 22, 7, 0) 68%, rgba(72, 22, 7, 0) 100%);
}

.main-banner-in::after {
    content: "";
    position: absolute;
    width: 900px;
    height: 900px;
    border-radius: 100%;
    top: -150px;
    right: -390px;
    opacity: 0.5;
    /* background: radial-gradient(ellipse at center, rgba(255, 82, 25, 0.6) 0%, rgba(255, 82, 25, 0.6) 0%, rgba(255, 82, 25, 0) 68%, rgba(255, 82, 25, 0) 100%);
    -webkit-background: radial-gradient(ellipse at center, rgba(255, 82, 25, 0.6) 0%, rgba(255, 82, 25, 0.6) 0%, rgba(255, 82, 25, 0) 68%, rgba(255, 82, 25, 0) 100%); */
    background: radial-gradient(ellipse at center, rgba(244, 136, 7, 0.6) 0%, rgba(244, 136, 7, 0.6) 0%, rgba(244, 136, 7, 0) 68%, rgba(244, 136, 7, 0) 100%);
    -webkit-background: radial-gradient(ellipse at center, rgba(244, 136, 7, 0.6) 0%, rgba(244, 136, 7, 0.6) 0%, rgba(244, 136, 7, 0) 68%, rgba(244, 136, 7, 0) 100%);
    z-index: -1;
}

/*==========Background color CSS End==========*/

/*==========Scroll To Top CSS Start==========*/
.scroll-top {
    position: fixed;
    bottom: 25px !important;
    right: 5px !important;
    width: 40px;
    height: 40px;
    background-color: #553cdf;
    border-radius: 100%;
    font-size: 25px;
    color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    overflow: hidden;
    z-index: 50;
    display: none;
}

.scroll-top:hover {
    box-shadow: 1px 1px 60px rgb(0 0 0 / 15%);
    transition: 0.3s;
    color: #ffffff;
}

.scroll-top:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #390f00;
    transition: 0.3s;
    z-index: 0;
}

.scroll-top:hover:before {
    top: 0;
}

.scroll-top i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 10px;
    z-index: 1;
}

.scroll-top:hover i {
    animation: swipe 0.6s linear infinite alternate;
}

@keyframes swipe {
    from {
        margin-top: -3px;
    }

    to {
        margin-top: 3px;
    }
}

/*==========Scroll To Top CSS End==========*/

/*==Sticky Header Start==*/
.site-header {

    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: 0.3s;
}

.site-header.sticky-header {
    position: fixed;
    background-color: rgb(255, 255, 255, 0.8);
    box-shadow: 6px 6px 60px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    -moz-backdrop-filter: blur(20px);
    -ms-backdrop-filter: blur(20px);
    transition: 0.3s
}

.sticky-header {
    padding: 20px 0px;
    animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    transition: 0.3s;
}

@keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }

    100% {
        margin-top: 0;
    }
}

.sticky-header .navbar-nav a {

    transition: 0.3s;
}

.sticky-header .navbar-brand-img {
    max-height: 7.5rem;
    transition: 0.3s;
    margin-bottom: 15px;
}

.sticky-header .navbar-brand {
    margin-top: 0px !important;
}

/*==Sticky Header End==*/
/* Floating images  Start */
.floating {  
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
   
    margin-top: 5px;
    }
    @keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
    }


    /* Floating images end */
.btn {
    background-color: #390f00;
    background-color: #ff7812;
}


.btn-primary {

    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-primary:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ff7812;
}

label,
.form-check-label,
.forgot {
    color: #501d0a;
}

.border-orange {
    border-color: #ff7812;
}

.bg-dark {
    background-color: #ff7812 !important;
}

.top-bar {
    border-bottom: 2px solid #390f00;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav a,
.text-dark {
    color: #390f00 !important;
}

.badge-primary {
    background-color: #553cdf;
}

.dropdown-header, .fa-angle-double-right {
    color: #ff7812;
}

.navbar-brand-img {
    max-height: 7.5rem;
    margin-bottom: 15px;
}

.text-secondary {
    /* color: white !important; */
}
.testimonial .border{
border:1px solid #390f00 !important;
}

.bg-teal {
    background-color: #553cdf!important;
    /* background: linear-gradient(244.83deg, #FF7812 0.17%, rgba(255, 120, 18, 0.548708) 51.58%, rgb(233 18 87 / 0%) 114.08%);
    background-color: black !important; */
}
.bg-teal1 {
   
    background: linear-gradient(244.83deg, #FF7812 0.17%, rgba(255, 120, 18, 0.548708) 51.58%, rgb(233 18 87 / 0%) 114.08%);
    background-color: black !important;
}
.collapse-accordion-toggle{
    font-size: 16px;
}
a.badge-primary:focus, a.badge-primary:hover{
    background-color: #390f00;
}
/* .btn-primary{
  
    background-image: linear-gradient(to right, #df8210,#5c1e0f, #ff7812, #390f00);
    background-image: linear-gradient(to right, #eb8e1e,#ff7812, #da8319, #ff7812);
} */

.cust-lang {
    width: 18px;
}
/* .overlay-primary::before{
    background-color: #ff7812;
} */
/* contact us page css start */

.form-control-flush, .form-label-group>.form-control-flush~label{
    padding-left: 10px;
}
.form-label-group>label{
    color: #390f00;
}
.cust-contact-bg{
background: #5c1e0f;}

.redeem-coupon{
    font-size: 1.4rem;
}
.udi-close:before {
    content: "\E730";
}
.bd-highlight a:hover{
    color: #ff7812;
}

.overlay-secondary::before {
    background-color: #390f00;
}
.single-lecture .sk-thumbnail{
    padding-bottom: 3rem;
}

.single-lecture .collapse-accordion-toggle{
    font-size: 15px;
}

.nav-vertical .nav-link:not(.active){
    color: #390f00;
}
.nav-vertical .nav-link.active{
    color: #ff7812;
}

.breadcrumb-item+.breadcrumb-item::before{
    color:white;
}
.breadcrumb-scroll .breadcrumb-item{
    font-size:16px;
}


.dropdown-toggle{
    border:none;
    background-color: transparent;
}
.dropdown-toggle::after{
    display: none;
}

.dropdown-item {
    width: auto;
    /* min-width: 100%; */
    margin-left: -1.24rem !important;
    margin-right: -1.24rem !important;
    font-size: 16px !important;
    cursor: pointer;
}

.field-icon {
    float: right;
    margin-right: 10px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
  }

.sk-thumbnail{
    /* padding-bottom: 0px; */
}

/* landing page css  start*/
.enroll{
    font-size: 20px;
}

.course-overlay{
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}
/* landing page css  End*/

.jarallax h4{
    color: #501d0a;
    font-weight: bold;
}

.list-style-v1 li::after{
    background-color: #ff7812;
}

.lesson-box{
    height: 410px;
    overflow-y: scroll;
}

.main-banner-in:after{
    right: 0;
}

.lesson-heading{
    background-color: #5c1e0f !important;
}
 

.lesson-playlist{
    height: 400px; 
    overflow-y: scroll;
}
 /* ibc css start  */
.ibc-btn{
    text-align: end;
}
.ibc-btn a{
    border: 1px solid;
    padding: 4% 12%;
    color: #ff7812;
    border-radius: 5px;
}

.ibc-paynow a{
    border: 1px solid;
    padding: 4% 20%;
    color: white;
    border-radius: 5px;
    background: #ff7812;
}

.ibc-paynow{
    text-align: left;
}

.foot_box {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    display: inline;
    border: 1px #fa8f0b solid;
    padding: 25px 20px;
    border-radius: 10px;
    margin: 10px;
    background: #fa8f0b;
    border-collapse: separate;
    border-spacing: 15px;
}
.foot_box span {
    color: #2c1e11;
    background-color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 100px;
    padding: 10px 20px;
}

.callsales i{
    vertical-align: middle;
}
.ibo-text{
    font-size: 20px;
}


.modal-header, .modal-body, .modal-footer{
    padding: 1.5rem 2.5rem;
}

@media (max-width: 425px){
.foot_box{
    display: grid;
}
}
/* ibc css end  */


  /* webinar page css start   */
.video-container {
    position: relative;
    width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
 }

 .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 0% !important; /* 16:9 Aspect Ratio */
  }

 /* .video-container iframe, .video-container object, .video-container embed { position: absolute1; top: 0; left: 0; width:     ; height: auto; }  */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

.webinar{
 font-size: 26px;
background: linear-gradient(41deg, #C96F00 0%, #FF9C00 70.88%);
box-shadow: 36.3019px -41.7606px 110.667px rgba(49, 27, 0, 0.289), inset -12.4233px 14.2914px 18.9363px #BD6900, inset 12.4233px -14.2914px 18.9363px #FFA300;
}
.webinar span{
    font-size: 18px;
    display: block;
}
#timer {
    font-size: 2.5em;
    font-weight: 600;
    color: #390f00;
    
     /* text-shadow: 0 0 20px #48C8FF;  */
}

#timer div {
    display: inline-block;
   width: 100px;
   
    border: 2px solid #390f00;
    border-radius: 10px;
    margin: 1%;
    padding: 0.5% 0.5%;
}

#timer div span {
    color: #f78900;
    display: block;
    font-size: .45em;
    font-weight: 400;
}

.webinar-table td , .webinar-table th{
    padding: 1rem;
}
.webinar-table th{
    color: #390f00 !important;
}
.webinar-table td a{
    border: 1px solid ;
    padding: 1% 5%;
    display: block;
}

 /* captcha css  */

.webinarmodal .modal-header, .webinarmodal .modal-body{
    padding: 1rem 2.5rem;
}
.webinarmodal .modal-footer{
    padding: 1rem;
}

 .captcha-code .code{
    width: 200px;
    height: 80px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    border: 1px solid #CCC;
}

 .captcha-code .code .dynamic-code{
    text-shadow: 4px 4px 5px rgba(150, 150, 150, 1);
    color: #2d2d2d;
    text-align: center;
    font-size: 28px;
    font-weight: 900;
    -webkit-touch-callout: none;   
    -webkit-user-select: none;     
    -khtml-user-select: none;      
    -moz-user-select: none;       
    -ms-user-select: none;         
    user-select: none;             
    cursor: help;
}

 .captcha-code .code .dynamic-code:first-letter{
    font-size: 48px;
}

 .captcha-code .captcha-reload{
    cursor: pointer;
    font-size: 36px;
    font-weight: bold;
    margin-top: 0;
    max-width: 36px;
}

 .captcha-code .captcha-reload:hover{
    color: #00B16A;
}
.captcha-code{float: left;}
        .captcha-input{
            max-width: 200px;
            margin-top: 15px;
            float: left;
            margin-left: 5px;
        }

@media (min-width: 768px){
.mw-md-450p {
    max-width: 450px!important;
}
.webinar-table td , .webinar-table th{
    padding: 1.5rem;
}
}

 /* Webinar page css end  */

/* contact us page css end */
@media (min-width: 1200px) {
    .navbar-brand-img {
        max-height: 9.6rem;
        margin-bottom: 20px;
        /* margin-left: 30rem !important; */
    }

    .navbar {
        height: 130px;
        background-color: #ffff !important;
    }
    .container-fluid{
        /* padding-left: 60px!important;
        padding-right: 60px!important; */
        padding: 0px;
    }
}

@media (max-width: 991.98px) {
    .navbar-nav .nav-item {
        padding: 1rem;
    }
    .navbar-collapse.show{
        display: inline-table;
    }

    
    
}
@media (max-width: 767.98px){
.course-tab-v1 .nav-link{
    padding: 10px 6px;
}
.main-banner-in::before, .main-banner-in::after{
    display: none;
}

}


