.metismenu .arrow {
    float: right;
    line-height: 1.42857;
  }
  *[dir="rtl"] .metismenu .arrow {
    float: left;
  }
  
  /*
   * Require Bootstrap 3.x
   * https://github.com/twbs/bootstrap
  */
  
  .metismenu .glyphicon.arrow:before {
    content: "\e079";
  }
  .metismenu .mm-active > a > .glyphicon.arrow:before {
    content: "\e114";
  }
  
  /*
   * Require Font-Awesome
   * http://fortawesome.github.io/Font-Awesome/
  */
  
  .metismenu .fa.arrow:before {
    content: "\f104";
  }
  .metismenu .mm-active > a > .fa.arrow:before {
    content: "\f107";
  }
  
  /*
   * Require Ionicons
   * http://ionicons.com/
  */
  
  .metismenu .ion.arrow:before {
    content: "\f3d2"
  }
  .metismenu .mm-active > a > .ion.arrow:before {
    content: "\f3d0";
  }
  .metismenu .plus-times {
    float: right;
  }
  *[dir="rtl"] .metismenu .plus-times {
    float: left;
  }
  .metismenu .fa.plus-times:before {
    content: "\f067";
  }
  .metismenu .mm-active > a > .fa.plus-times {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .metismenu .plus-minus {
    float: right;
  }
  *[dir="rtl"] .metismenu .plus-minus {
    float: left;
  }
  .metismenu .fa.plus-minus:before {
    content: "\f067";
  }
  .metismenu .mm-active > a > .fa.plus-minus:before {
    content: "\f068";
  }
  .metismenu .mm-collapse:not(.mm-show) {
    display: none;
  }
  
  .metismenu .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
         -o-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .35s;
         -o-transition-duration: .35s;
            transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
  }
  
  .metismenu .has-arrow {
    position: relative;
  }
  
  .metismenu .has-arrow::after {
    position: absolute;
    content: '';
    width: .5em;
    height: .5em;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: initial;
    right: 1em;
    -webkit-transform: rotate(-45deg) translate(0, -50%);
        -ms-transform: rotate(-45deg) translate(0, -50%);
            transform: rotate(-45deg) translate(0, -50%);
    -webkit-transform-origin: top;
        -ms-transform-origin: top;
            transform-origin: top;
    top: 50%;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  
  *[dir="rtl"] .metismenu .has-arrow::after {
    right: auto;
    left: 1em;
    -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
            transform: rotate(135deg) translate(0, -50%);
  }
  
  .metismenu .mm-active > .has-arrow::after,
  .metismenu .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
        -ms-transform: rotate(-135deg) translate(0, -50%);
            transform: rotate(-135deg) translate(0, -50%);
  }
  
  *[dir="rtl"] .metismenu .mm-active > .has-arrow::after,
  *[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(225deg) translate(0, -50%);
        -ms-transform: rotate(225deg) translate(0, -50%);
            transform: rotate(225deg) translate(0, -50%);
  }